import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { App as AppProvider } from 'antd'
import { BugsnagWrapper } from './config/BugsnagWrapper'
import Loading from './pages/loading/Loading'
import { store, persistor } from './redux/store'
import App from './App'
import QueryClientWrapper from './services/api/QueryClientWrapper'
import AppConfigProvider from './config/AppConfigProvider'

const Root: React.FC = () => {
  return (
    <AppProvider>
      <QueryClientWrapper>
        <BugsnagWrapper>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Suspense fallback={<Loading />}>
                <AppConfigProvider>
                  <App />
                </AppConfigProvider>
              </Suspense>
            </PersistGate>
          </Provider>
        </BugsnagWrapper>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientWrapper>
    </AppProvider>
  )
}

export default Root
