import React from 'react'
import {
  AlignLeftOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  BlockOutlined,
  BuildOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  ClusterOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined,
  FileSearchOutlined,
  FormatPainterOutlined,
  FundOutlined,
  GoldOutlined,
  IdcardOutlined,
  KeyOutlined,
  NumberOutlined,
  OrderedListOutlined,
  PartitionOutlined,
  PieChartOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  RiseOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  SolutionOutlined,
  StockOutlined,
  TeamOutlined
} from '@ant-design/icons'
import { isRouteErrorResponse, Navigate, useRouteError } from 'react-router-dom'
import { AppRoute, Route } from './Route'
import { ContractFranchise, ContractProduct } from '../types/contract/Contract'
import { Country } from '../types/company/Company'
import { voucherAccountingSoftware } from '../config/integrations/vouchers'
import BasicLayout from '../layouts/BasicLayout'
import ForgotPassword from '../pages/user/forgotPassword/ForgotPassword'
import IndustryAndCompetitorComparison from '../pages/industryAndCompetitorComparison/IndustryAndCompetitorComparison'
import ResetPassword from '../pages/user/resetPassword/ResetPassword'
import UserLayout from '../layouts/UserLayout'
import ValueEvaluation from '../pages/valuation/Valuation'
import FundingApplications from '../pages/fundingApplications/FundingApplications'
import CompanyInformation from '../pages/companyInformation/CompanyInformation'
import DeliveryDownload from '../components/Delivery/DeliveryDownload'
import Vouchers from '../pages/reporting/vouchers/Vouchers'
import Root from '../Root'
import CompanyGeneralSettings from '../pages/settings/company/general/CompanyGeneralSettings'
import SettingLayout from '../layouts/SettingLayout'
import AppearanceSettings from '../pages/settings/company/general/Appearance/AppearanceSettings'
import Contracts from '../components/Contracts/Contracts'
import CustomReportOrder from '../pages/reporting/custom/components/settings/CustomReportOrder'
import AccountantAccountMap from '../pages/settings/accountant/accountMap/AccountantAccountMap'
import AccountantLogo from '../pages/settings/accountant/general/AccountantLogo'
import AccountorScorecardSubscriptions from '../pages/settings/accountant/subscriptions/company-dashboard/AccountorScorecardSubscriptions'
import CompanyDashboardSubscriptions from '../pages/settings/accountant/subscriptions/company-dashboard/CompanyDashboardSubscriptions'
import CustomReportsSubscriptions from '../pages/settings/accountant/subscriptions/company-dashboard/CustomReportsSubscriptions'
import AccountantUsers from '../pages/settings/accountant/users/AccountantUsers'
import CompanyAccountMap from '../pages/settings/company/accountMap/CompanyAccountMap'
import BudgetDrivers from '../pages/settings/company/budgeting/budgetDrivers/BudgetDrivers'
import BudgetGoogleSheets from '../pages/settings/company/budgeting/budgetingImport/BudgetGoogleSheets'
import BudgetingCSVImport from '../pages/settings/company/budgeting/budgetingImport/BudgetingImport'
import BudgetOneDrive from '../pages/settings/company/budgeting/budgetingImport/BudgetOneDrive'
import BudgetingInput from '../pages/settings/company/budgeting/budgetingInput/BudgetingInput'
import BudgetingScenarios from '../pages/settings/company/budgeting/budgetingScenarios/BudgetingScenarios'
import Forecasts from '../pages/settings/company/budgeting/forecasts/Forecasts'
import Dimensions from '../pages/settings/company/dimensions/Dimensions'
import FiscalYears from '../pages/settings/company/fiscalYears/FiscalYears'
import ExchangeRate from '../pages/settings/company/group/exchangeRate/ExchangeRate'
import ExchangeRateEdit from '../pages/settings/company/group/exchangeRate/ExchangeRateEdit'
import ExchangeRateTable from '../pages/settings/company/group/exchangeRate/ExchangeRateTable'
import Figures from '../pages/settings/company/group/figures/Figures'
import { Subsidiaries } from '../pages/settings/company/group/subsidiaries/Subsidiaries'
import ActualsGoogleSheets from '../pages/settings/company/integrations/accounting/ActualsGoogleSheets'
import ActualsOneDrive from '../pages/settings/company/integrations/accounting/ActualsOneDrive'
import Briox from '../pages/settings/company/integrations/accounting/Briox'
import Econia from '../pages/settings/company/integrations/accounting/Econia'
import Efina from '../pages/settings/company/integrations/accounting/Efina'
import ExcelImport from '../pages/settings/company/integrations/accounting/Excel'
import Fennoa from '../pages/settings/company/integrations/accounting/Fennoa'
import Fivaldi from '../pages/settings/company/integrations/accounting/Fivaldi'
import Fortnox from '../pages/settings/company/integrations/accounting/Fortnox'
import Heeros from '../pages/settings/company/integrations/accounting/Heeros'
import Lemonsoft from '../pages/settings/company/integrations/accounting/Lemonsoft'
import Maestro from '../pages/settings/company/integrations/accounting/Maestro'
import Netvisor from '../pages/settings/company/integrations/accounting/Netvisor'
import Oscar from '../pages/settings/company/integrations/accounting/Oscar'
import Procountor from '../pages/settings/company/integrations/accounting/Procountor'
import Sonet from '../pages/settings/company/integrations/accounting/Sonet'
import Talenom from '../pages/settings/company/integrations/accounting/Talenom'
import VismaL7 from '../pages/settings/company/integrations/accounting/VismaL7'
import Vismanet from '../pages/settings/company/integrations/accounting/Vismanet'
import Xero from '../pages/settings/company/integrations/accounting/Xero'
import AcPanther from '../pages/settings/company/integrations/external/acPanther/AcPanther'
import ExternalGoogleSheets from '../pages/settings/company/integrations/external/ExternalGoogleSheets'
import Futursoft from '../pages/settings/company/integrations/external/futursoft/Futursoft'
import HrSuunti from '../pages/settings/company/integrations/external/hrsuunti/HrSuunti'
import Kiho from '../pages/settings/company/integrations/external/kiho/Kiho'
import Kotipizza from '../pages/settings/company/integrations/external/kotipizza/Kotipizza'
import Logisystems from '../pages/settings/company/integrations/external/logisystems/Logisystems'
import Mapon from '../pages/settings/company/integrations/external/mapon/Mapon'
import Maraplan from '../pages/settings/company/integrations/external/maraplan/Maraplan'
import ExternalOneDrive from '../pages/settings/company/integrations/external/onedrive/ExternalOneDrive'
import Restolution from '../pages/settings/company/integrations/external/restolution/Restolution'
import Severa from '../pages/settings/company/integrations/external/severa/Severa'
import Tamigo from '../pages/settings/company/integrations/external/tamigo/Tamigo'
import Tulopos from '../pages/settings/company/integrations/external/tulopos/Tulopos'
import Formulas from '../pages/settings/company/keyFigures/formulas/Formulas'
import Subscribed from '../pages/settings/company/keyFigures/subscribed/Subscribed'
import PersonnelPage from '../pages/settings/company/personnel/PersonnelPage'
import ReallocationPage from '../pages/settings/company/reallocation/ReallocationPage'
import CompanyUsers from '../pages/settings/company/users/CompanyUsers'
import FinancierUsers from '../pages/settings/financier/users/FinancierUsers'
import UserGeneralSettings from '../pages/settings/user/general/UserGeneralSettings'
import Password from '../pages/settings/user/password/Password'
import Profile from '../pages/settings/user/profile/Profile'
import FinancialStatements from '../pages/budgeting/financialStatements/FinancialStatements'
import { ContextFiltersSections } from '../components/ContextDrawer/types'
import CashFlow from '../pages/reporting/cashFlow/CashFlow'
import LayoutsProvider from '../pages/reporting/custom/components/layout/LayoutContext'
import CustomReportsNew from '../pages/reporting/custom/CustomReportsNew'
import KeyFigures from '../pages/reporting/keyFigures/KeyFigures'
import CompanyDashboard from '../pages/dashboard/companyDashboard/CompanyDashboard'
import CompanyDashboardItemProvider from '../pages/dashboard/companyDashboard/components/DashboardItemEdit/DashboardItemProvider'
import MultiCompanyDashboard from '../pages/dashboard/multiCompanyDashboard/MultiCompanyDashboard'
import MultiPurposeDashboardCompact from '../pages/dashboard/multiPurposeDashboard/MultiPurposeDashboardCompact'
import MultiPurposeDashboardDefault from '../pages/dashboard/multiPurposeDashboard/MultiPurposeDashboardDefault'
import ScenarioDashboard from '../pages/dashboard/scenarioDashboard/ScenarioDashboard'
import SuperUserContract from '../pages/superuser/contracts/components/SuperUserContract'
import SuperUserContracts from '../pages/superuser/contracts/SuperUserContracts'
import Deliveries from '../pages/superuser/deliveries/Deliveries'
import { Integration } from '../pages/superuser/integration/Integration'
import SuperUserUser from '../pages/superuser/users/components/SuperUserUser'
import SuperUserUsers from '../pages/superuser/users/SuperUserUsers'
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute'
import {
  isEliminationCompany,
  isGroupCompany,
  multiCompany,
  showAccountorLayout,
  showBudgetingSettings,
  showEconiaNovaIntegration
} from './utils'
import Accounts from '../features/account/components/Accounts'
import Login from '../pages/user/login/Login'
import LoanPage from '../pages/budgeting/loans/LoanPage'
import InvestmentsPage from '../pages/budgeting/investments/InvestmentsPage'
import KeyPerformanceIndicatorsPage from '../pages/settings/company/keyFigures/keyPerformanceIndicators/KeyPerformanceIndicatorsPage'

function RootBoundary() {
  const error = useRouteError()

  console.log('error', error)

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <div>This page doesnt exist!</div>
    }

    if (error.status === 401) {
      return <div>You arent authorized to see this</div>
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>
    }

    if (error.status === 418) {
      return <div>🫖</div>
    }
  }

  return <div>Something went wrong</div>
}

const routes: AppRoute[] = [
  {
    hideInMenu: true,
    element: <Root />,
    routes: [
      {
        hideInMenu: true,
        element: <UserLayout />,
        routes: [
          {
            path: '/user/login',
            name: 'login',
            hideInMenu: true,
            element: <Login />
          },
          {
            path: '/user/forgot-password',
            name: 'forgot-password',
            hideInMenu: true,
            element: <ForgotPassword />
          },
          {
            path: '/user/reset-password',
            name: 'reset-password',
            hideInMenu: true,
            element: <ResetPassword extra="" />
          },
          {
            path: '/user/activate',
            name: 'activate',
            hideInMenu: true,
            element: <ResetPassword extra="usersPage:contact-finadeck" />
          }
        ]
      },
      {
        element: <ProtectedRoute />,
        routes: [
          {
            element: <BasicLayout />,
            routes: [
              // /dashboard
              {
                path: '/dashboard',
                name: 'dashboard',
                icon: <DashboardOutlined />,
                routes: [
                  {
                    path: '/dashboard/accountant',
                    name: 'accountant',
                    authority: { role: ['accountant-admin', 'accountant-user'] },
                    element: <MultiCompanyDashboard />
                  },
                  {
                    path: '/dashboard/financier',
                    name: 'financier',
                    authority: { role: ['financier-admin', 'financier-user'] },
                    element: <MultiCompanyDashboard />
                  },
                  {
                    path: '/dashboard/companies',
                    name: 'companies',
                    authority: {
                      role: ['company-admin', 'company-user'],
                      custom: multiCompany
                    },
                    element: <MultiCompanyDashboard />,
                    disabledContextdrawerSections: [
                      ContextFiltersSections.budgetScenario,
                      ContextFiltersSections.dimension,
                      ContextFiltersSections.periodGroups,
                      ContextFiltersSections.dataTypes
                    ]
                  },
                  {
                    path: '/dashboard/company',
                    name: 'company',
                    authority: {
                      role: [
                        'company-admin',
                        'company-user',
                        'accountant-admin',
                        'accountant-user',
                        'financier-admin',
                        'financier-user'
                      ]
                    },
                    element: (
                      <CompanyDashboardItemProvider>
                        <CompanyDashboard />
                      </CompanyDashboardItemProvider>
                    )
                  },
                  {
                    path: '/dashboard/dimensions',
                    name: 'dimensions',
                    authority: {
                      role: [
                        'company-admin',
                        'company-user',
                        'accountant-admin',
                        'accountant-user',
                        'financier-admin',
                        'financier-user'
                      ]
                    },
                    routes: [
                      {
                        path: '/dashboard/dimensions/default',
                        name: 'default',
                        element: <MultiPurposeDashboardDefault />
                      },
                      {
                        path: '/dashboard/dimensions/compact',
                        name: 'compact',
                        element: <MultiPurposeDashboardCompact />
                      }
                    ]
                  },
                  {
                    path: '/dashboard/scenarios',
                    name: 'scenarios',
                    element: <ScenarioDashboard />,
                    authority: {
                      role: [
                        'company-admin',
                        'company-user',
                        'accountant-admin',
                        'accountant-user',
                        'financier-admin',
                        'financier-user'
                      ]
                    }
                  }
                ]
              },
              // /reporting
              {
                path: '/reporting',
                name: 'reporting',
                icon: <BarChartOutlined />,
                routes: [
                  {
                    path: '/reporting/financial-statements',
                    name: 'financial-statements',
                    element: <FinancialStatements />,
                    authority: [
                      // Tuotteena Light, raportoinnin alla, muissa Budjetoinnissa
                      {
                        role: [
                          'company-admin',
                          'company-user',
                          'accountant-admin',
                          'accountant-user',
                          'financier-admin',
                          'financier-user'
                        ],
                        product: [ContractProduct.LIGHT]
                      },
                      // Konsernin tulos ja tase toistaseks täällä
                      {
                        custom: isGroupCompany
                      }
                    ]
                  },
                  {
                    path: '/reporting/key-figures',
                    name: 'key-figures',
                    element: <KeyFigures />,
                    authority: {
                      role: [
                        'company-admin',
                        'company-user',
                        'accountant-admin',
                        'accountant-user',
                        'financier-admin',
                        'financier-user'
                      ]
                    }
                  },
                  {
                    path: '/reporting/cash-flow',
                    name: 'cash-flow',
                    element: <CashFlow />,
                    authority: {
                      role: [
                        'company-admin',
                        'company-user',
                        'accountant-admin',
                        'accountant-user',
                        'financier-admin',
                        'financier-user'
                      ]
                    }
                  },
                  {
                    path: '/reporting/custom',
                    name: 'custom',
                    element: (
                      <LayoutsProvider>
                        <CustomReportsNew />
                      </LayoutsProvider>
                    ),
                    disabledContextdrawerSections: [
                      ContextFiltersSections.budgetScenario,
                      ContextFiltersSections.dimension,
                      ContextFiltersSections.periodGroups,
                      ContextFiltersSections.dataTypes
                    ]
                  }
                ]
              },
              // /budgeting
              {
                path: '/budgeting',
                name: 'budgeting',
                icon: <CalculatorOutlined />,
                authority: {
                  role: [
                    'company-admin',
                    'company-user',
                    'accountant-admin',
                    'accountant-user',
                    'financier-admin',
                    'financier-user'
                  ],
                  product: [ContractProduct.BASIC, ContractProduct.PRO]
                },
                routes: [
                  {
                    path: '/budgeting/financial-statements',
                    name: 'financial-statements',
                    element: <FinancialStatements />
                  },
                  {
                    path: '/budgeting/loans',
                    name: 'loans',
                    authority: {
                      permission: 'read-root-dimension'
                    },
                    routes: [
                      {
                        path: '/budgeting/loans/existing',
                        name: 'existing',
                        element: <LoanPage isExisting />
                      },
                      {
                        path: '/budgeting/loans/new',
                        name: 'new',
                        element: <LoanPage isExisting={false} />
                      }
                    ]
                  }
                ]
              },
              {
                path: '/vouchers',
                name: 'vouchers',
                icon: <FileSearchOutlined />,
                element: <Vouchers />,
                authority: [
                  {
                    permission: 'read-vouchers'
                  },
                  {
                    role: ['company-admin', 'accountant-admin', 'accountant-user'],
                    accountingSoftware: voucherAccountingSoftware
                  }
                ]
              },
              // /investments
              {
                path: '/investments',
                name: 'investments',
                icon: <BlockOutlined />,
                authority: {
                  product: ContractProduct.PRO
                },
                routes: [
                  {
                    path: '/investments/existing',
                    name: 'existing',
                    element: <InvestmentsPage isExisting />
                  },
                  {
                    path: '/investments/investment',
                    name: 'investment',
                    element: <InvestmentsPage isExisting={false} />
                  }
                ]
              },
              {
                path: '/financing',
                name: 'financing',
                icon: <FundOutlined />,
                authority: [
                  {
                    role: ['company-admin', 'accountant-admin'],
                    product: [ContractProduct.BASIC, ContractProduct.PRO]
                  },
                  {
                    custom: () => !isEliminationCompany()
                  }
                ],
                routes: [
                  {
                    path: '/financing/funding-applications',
                    name: 'funding-applications',
                    icon: <FundOutlined />,
                    element: <FundingApplications />
                  }
                ]
              },
              {
                path: '/comparison',
                name: 'comparison',
                icon: <RiseOutlined />,
                element: <IndustryAndCompetitorComparison />,
                authority: [
                  {
                    role: [
                      'company-admin',
                      'company-user',
                      'accountant-admin',
                      'accountant-user',
                      'financier-admin',
                      'financier-user'
                    ],
                    country: Country.fi
                  },
                  {
                    custom: () => !isEliminationCompany()
                  }
                ]
              },
              {
                path: '/valuation',
                name: 'valuation',
                icon: <GoldOutlined />,
                element: <ValueEvaluation />,
                authority: [
                  {
                    role: [
                      'company-admin',
                      'company-user',
                      'accountant-admin',
                      'accountant-user',
                      'financier-admin',
                      'financier-user'
                    ],
                    product: [ContractProduct.BASIC, ContractProduct.PRO]
                  },
                  {
                    custom: () => !isEliminationCompany()
                  }
                ]
              },
              {
                path: '/company-information',
                name: 'companyInformation',
                icon: <DatabaseOutlined />,
                element: <CompanyInformation />,
                authority: {
                  custom: () => !isEliminationCompany()
                }
              },

              // /settings
              {
                path: '/settings',
                name: 'settings',
                icon: <SettingOutlined />,
                hideInMenu: true,
                routes: [
                  {
                    path: '/settings/custom-report',
                    name: 'custom-report',
                    element: <SettingLayout />,
                    hideInMenu: true,
                    routes: [
                      {
                        path: '/settings/custom-report/order',
                        name: 'custom-report-order',
                        icon: <OrderedListOutlined />,
                        element: <CustomReportOrder />
                      }
                    ]
                  },
                  {
                    path: '/settings/user',
                    name: 'user',
                    element: <SettingLayout />,
                    routes: [
                      {
                        path: '/settings/user/profile',
                        name: 'profile',
                        element: <Profile />
                      },
                      {
                        path: '/settings/user/general',
                        name: 'general',
                        element: <UserGeneralSettings />
                      },
                      {
                        path: '/settings/user/password',
                        name: 'password',
                        element: <Password />
                      }
                    ]
                  },
                  {
                    path: '/settings/accountant',
                    name: 'accountant',
                    element: <SettingLayout />,
                    authority: { role: ['accountant-admin', 'accountant-user'] },
                    routes: [
                      {
                        path: '/settings/accountant/users',
                        name: 'users',
                        icon: <TeamOutlined />,
                        element: <AccountantUsers />
                      },
                      {
                        path: '/settings/accountant/general',
                        name: 'general',
                        icon: <ReadOutlined />,
                        routes: [
                          {
                            path: '/settings/accountant/general/logoupload',
                            name: 'logoupload',
                            icon: <IdcardOutlined />,
                            element: <AccountantLogo />
                          }
                        ]
                      },
                      {
                        path: '/settings/accountant/contracts',
                        name: 'contracts',
                        icon: <AuditOutlined />,
                        element: <Contracts />
                      },
                      {
                        path: '/settings/accountant/account-map',
                        name: 'account-map',
                        icon: <AlignLeftOutlined />,
                        element: <AccountantAccountMap />
                      },
                      {
                        path: '/settings/accountant/subscriptions',
                        name: 'subscriptions',
                        icon: <ReconciliationOutlined />,
                        routes: [
                          {
                            path: '/settings/accountant/subscriptions/company-dashboard',
                            name: 'company-dashboard',
                            icon: <PieChartOutlined />,
                            element: <CompanyDashboardSubscriptions />
                          },
                          {
                            path: '/settings/accountant/subscriptions/custom-reports',
                            name: 'custom-reports',
                            icon: <ClusterOutlined />,
                            element: <CustomReportsSubscriptions />
                          },
                          {
                            path: '/settings/accountant/subscriptions/accountor-scorecard',
                            name: 'accountor-scorecard',
                            icon: <StockOutlined />,
                            element: <AccountorScorecardSubscriptions />,
                            authority: {
                              custom: showAccountorLayout
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: '/settings/financier',
                    name: 'accountant',
                    element: <SettingLayout />,
                    authority: { role: ['financier-admin', 'financier-user'] },
                    routes: [
                      {
                        path: '/settings/financier/users',
                        name: 'users',
                        icon: <TeamOutlined />,
                        element: <FinancierUsers />
                      },
                      {
                        path: '/settings/financier/contracts',
                        name: 'contracts',
                        icon: <AuditOutlined />,
                        element: <Contracts />
                      },
                      {
                        path: '/settings/financier/account-map',
                        name: 'account-map',
                        icon: <AlignLeftOutlined />,
                        element: <AccountantAccountMap />
                      },
                      {
                        path: '/settings/financier/subscriptions',
                        name: 'subscriptions',
                        icon: <ReconciliationOutlined />,
                        routes: [
                          {
                            path: '/settings/financier/subscriptions/company-dashboard',
                            name: 'company-dashboard',
                            icon: <PieChartOutlined />,
                            element: <CompanyDashboardSubscriptions />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: '/settings/company',
                    name: 'company',
                    element: <SettingLayout />,
                    authority: {
                      role: [
                        'company-admin',
                        'company-user',
                        'accountant-admin',
                        'accountant-user',
                        'financier-admin',
                        'financier-user'
                      ]
                    },
                    routes: [
                      {
                        path: '/settings/company/general',
                        name: 'general',
                        icon: <SettingOutlined />,
                        element: <CompanyGeneralSettings />
                      },
                      {
                        path: '/settings/company/appearance',
                        name: 'appearance',
                        icon: <FormatPainterOutlined />,
                        element: <AppearanceSettings />
                      },
                      {
                        path: '/settings/company/group',
                        name: 'group',
                        icon: <DeploymentUnitOutlined />,
                        authority: {
                          custom: isGroupCompany
                        },
                        routes: [
                          {
                            path: '/settings/company/group/subsidiaries',
                            name: 'subsidiaries',
                            icon: <BuildOutlined />,
                            element: <Subsidiaries />
                          },
                          {
                            path: '/settings/company/group/figures',
                            name: 'figures',
                            icon: <NumberOutlined />,
                            element: <Figures />
                          },
                          {
                            path: '/settings/company/group/exchangeRate',
                            name: 'exchangeRate',
                            icon: <StockOutlined />,
                            element: <ExchangeRate />,
                            routes: [
                              {
                                index: true,
                                hideInMenu: true,
                                element: <ExchangeRateTable />
                              },
                              {
                                path: '/settings/company/group/exchangeRate/edit',
                                name: 'edit',
                                hideInMenu: true,
                                element: <ExchangeRateEdit />
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: '/settings/company/users',
                        name: 'users',
                        icon: <TeamOutlined />,
                        element: <CompanyUsers />
                      },
                      {
                        path: '/settings/company/integrations',
                        name: 'integrations',
                        icon: <ApiOutlined />,
                        routes: [
                          {
                            path: '/settings/company/integrations/accounting',
                            name: 'accounting',
                            routes: [
                              {
                                path: '/settings/company/integrations/accounting/briox',
                                name: 'briox',
                                element: <Briox />
                              },
                              {
                                path: '/settings/company/integrations/accounting/efina',
                                name: 'efina',
                                element: <Efina />
                              },
                              {
                                path: '/settings/company/integrations/accounting/excel',
                                name: 'excel',
                                element: <ExcelImport />
                              },
                              {
                                path: '/settings/company/integrations/accounting/fennoa',
                                name: 'fennoa',
                                element: <Fennoa />
                              },
                              {
                                path: '/settings/company/integrations/accounting/fivaldi',
                                name: 'fivaldi',
                                element: <Fivaldi />
                              },
                              {
                                path: '/settings/company/integrations/accounting/fortnox',
                                name: 'fortnox',
                                element: <Fortnox />
                              },
                              {
                                path: '/settings/company/integrations/accounting/actuals-google-sheets',
                                name: 'actuals-google-sheets',
                                element: <ActualsGoogleSheets />
                              },
                              {
                                path: '/settings/company/integrations/accounting/heeros',
                                name: 'heeros',
                                element: <Heeros />
                              },
                              {
                                path: '/settings/company/integrations/accounting/lemonsoft',
                                name: 'lemonsoft',
                                element: <Lemonsoft />
                              },
                              {
                                path: '/settings/company/integrations/accounting/maestro',
                                name: 'maestro',
                                element: <Maestro />
                              },
                              {
                                path: '/settings/company/integrations/accounting/netvisor',
                                name: 'netvisor',
                                element: <Netvisor />
                              },
                              {
                                path: '/settings/company/integrations/accounting/econia-nova',
                                name: 'econia-nova',
                                element: <Econia />,
                                authority: {
                                  custom: showEconiaNovaIntegration
                                }
                              },
                              {
                                path: '/settings/company/integrations/accounting/onedrive',
                                name: 'onedrive',
                                element: <ActualsOneDrive />
                              },
                              {
                                path: '/settings/company/integrations/accounting/oscar',
                                name: 'oscar',
                                element: <Oscar />
                              },
                              {
                                path: '/settings/company/integrations/accounting/procountor',
                                name: 'procountor',
                                element: <Procountor />
                              },
                              {
                                path: '/settings/company/integrations/accounting/sonet',
                                name: 'sonet',
                                element: <Sonet />
                              },
                              {
                                path: '/settings/company/integrations/accounting/talenom',
                                name: 'talenom',
                                element: <Talenom />
                              },
                              {
                                path: '/settings/company/integrations/accounting/vismaL7',
                                name: 'vismaL7',
                                element: <VismaL7 />
                              },
                              {
                                path: '/settings/company/integrations/accounting/vismanet',
                                name: 'vismane',
                                element: <Vismanet />
                              },
                              {
                                path: '/settings/company/integrations/accounting/xero',
                                name: 'xero',
                                element: <Xero />
                              }
                            ]
                          },
                          {
                            path: '/settings/company/integrations/external',
                            name: 'external',
                            routes: [
                              {
                                path: '/settings/company/integrations/external/ac-panther',
                                name: 'acpanther',
                                element: <AcPanther />
                              },
                              {
                                path: '/settings/company/integrations/external/futursoft',
                                name: 'futursoft',
                                element: <Futursoft />
                              },
                              {
                                path: '/settings/company/integrations/external/google-sheets',
                                name: 'google-sheets',
                                element: <ExternalGoogleSheets />
                              },
                              {
                                path: '/settings/company/integrations/external/hrsuunti',
                                name: 'hrsuunti',
                                element: <HrSuunti />
                              },
                              {
                                path: '/settings/company/integrations/external/kiho',
                                name: 'kiho',
                                element: <Kiho />
                              },
                              {
                                path: '/settings/company/integrations/external/kotipizza',
                                name: 'kotipizza',
                                element: <Kotipizza />,
                                authority: {
                                  franchise: ContractFranchise.KOTIPIZZA
                                }
                              },
                              {
                                path: '/settings/company/integrations/external/logisystems',
                                name: 'logisystems',
                                element: <Logisystems />
                              },
                              {
                                path: '/settings/company/integrations/external/mapon',
                                name: 'mapon',
                                element: <Mapon />
                              },
                              {
                                path: '/settings/company/integrations/external/maraplan',
                                name: 'maraplan',
                                element: <Maraplan />
                              },
                              {
                                path: '/settings/company/integrations/external/onedrive',
                                name: 'external-onedrive',
                                element: <ExternalOneDrive />
                              },
                              {
                                path: '/settings/company/integrations/external/restolution',
                                name: 'restolution',
                                element: <Restolution />
                              },
                              {
                                path: '/settings/company/integrations/external/severa',
                                name: 'severa',
                                element: <Severa />
                              },
                              {
                                path: '/settings/company/integrations/external/tamigo',
                                name: 'tamigo',
                                element: <Tamigo />
                              },
                              {
                                path: '/settings/company/integrations/external/tulopos',
                                name: 'tulopos',
                                element: <Tulopos />
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: '/settings/company/fiscal-years',
                        name: 'fiscal-years',
                        icon: <CalendarOutlined />,
                        element: <FiscalYears />
                      },
                      {
                        path: '/settings/company/dimensions',
                        name: 'dimensions',
                        icon: <ApartmentOutlined />,
                        element: <Dimensions />
                      },
                      {
                        path: '/settings/company/personnel',
                        name: 'personnel',
                        icon: <SolutionOutlined />,
                        element: <PersonnelPage />
                      },
                      {
                        path: '/settings/company/accounts',
                        name: 'accounts',
                        icon: <DatabaseOutlined />,
                        element: <Accounts />
                      },
                      {
                        path: '/settings/company/account-map',
                        name: 'account-map',
                        icon: <AlignLeftOutlined />,
                        element: <CompanyAccountMap />
                      },
                      {
                        path: '/settings/company/reallocation',
                        name: 'reallocation',
                        icon: <PartitionOutlined />,
                        element: <ReallocationPage />,
                        authority: {
                          product: ContractProduct.PRO,
                          custom: () => !isGroupCompany()
                        }
                      },
                      {
                        path: '/settings/company/budgeting',
                        name: 'budgeting',
                        icon: <BankOutlined />,
                        authority: {
                          product: [ContractProduct.BASIC, ContractProduct.PRO],
                          custom: showBudgetingSettings
                        },
                        routes: [
                          {
                            path: '/settings/company/budgeting/input',
                            name: 'input',
                            element: <BudgetingInput />,
                            authority: {
                              custom: showBudgetingSettings
                            }
                          },
                          {
                            path: '/settings/company/budgeting/drivers',
                            name: 'drivers',
                            element: <BudgetDrivers />,
                            authority: {
                              custom: showBudgetingSettings
                            }
                          },

                          {
                            path: '/settings/company/budgeting/import',
                            name: 'import',
                            routes: [
                              {
                                path: '/settings/company/budgeting/import/csv',
                                name: 'csv',
                                element: <BudgetingCSVImport />,
                                authority: {
                                  custom: showBudgetingSettings
                                }
                              },
                              {
                                path: '/settings/company/budgeting/import/google-sheet',
                                name: 'google-sheet',
                                element: <BudgetGoogleSheets />,
                                authority: {
                                  custom: showBudgetingSettings
                                }
                              },
                              {
                                path: '/settings/company/budgeting/import/onedrive',
                                name: 'import-onedrive',
                                element: <BudgetOneDrive />,
                                authority: {
                                  custom: showBudgetingSettings
                                }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: '/settings/company/budgeting/budgeting-scenarios',
                        name: 'budgeting-scenarios',
                        element: <BudgetingScenarios />
                      },
                      {
                        path: '/settings/company/budgeting/forecasts',
                        name: 'forecasts',
                        element: <Forecasts />
                      },
                      {
                        path: '/settings/company/key-figures',
                        name: 'key-figures',
                        icon: <KeyOutlined />,
                        routes: [
                          {
                            path: '/settings/company/key-figures/subscribed',
                            name: 'subscribed',
                            element: <Subscribed />
                          },
                          {
                            path: '/settings/company/key-figures/key-performance-indicators',
                            name: 'key-performance-indicators',
                            element: <KeyPerformanceIndicatorsPage />
                          },
                          {
                            path: '/settings/company/key-figures/formulas',
                            name: 'formulas',
                            element: <Formulas />
                          }
                          // {
                          //   path: '/settings/company/key-figures/dimension-dashboard',
                          //   name: 'dimension-dashboard'
                          // }
                          // {
                          //   path: '/settings/company/key-figures/financial-structure',
                          //   name: 'financial-structure'
                          // }
                        ]
                      },
                      {
                        path: '/settings/company/subscriptions',
                        name: 'subscriptions',
                        icon: <ReconciliationOutlined />,
                        authority: {
                          permission: 'read-root-dimension'
                        },
                        routes: [
                          {
                            path: '/settings/company/subscriptions/company-dashboard',
                            name: 'company-dashboard',
                            icon: <PieChartOutlined />,
                            element: <CompanyDashboardSubscriptions />
                          },
                          {
                            path: '/settings/company/subscriptions/custom-reports',
                            name: 'custom-reports',
                            icon: <ClusterOutlined />,
                            element: <CustomReportsSubscriptions />
                          },
                          {
                            path: '/settings/company/subscriptions/accountor-scorecard',
                            name: 'accountor-scorecard',
                            icon: <StockOutlined />,
                            element: <AccountorScorecardSubscriptions />,
                            authority: {
                              custom: showAccountorLayout
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              },

              {
                path: '/deliveries',
                name: 'deliveries',
                // urlParam: 'uuid',
                element: <DeliveryDownload />,
                hideInMenu: true
              },
              // /superuser
              {
                path: '/superuser',
                name: 'superuser',
                icon: <SafetyCertificateOutlined />,
                element: <SettingLayout />,
                authority: { role: 'superuser' },
                routes: [
                  {
                    path: '/superuser/contracts',
                    name: 'contracts',
                    element: <SuperUserContracts />
                  },
                  {
                    path: '/superuser/contracts/:id',
                    name: 'contract',
                    element: <SuperUserContract />,
                    hideInMenu: true
                  },
                  {
                    path: '/superuser/users',
                    name: 'users',
                    element: <SuperUserUsers />
                  },
                  {
                    path: '/superuser/users/:id',
                    name: 'user',
                    element: <SuperUserUser />,
                    hideInMenu: true
                  },
                  {
                    path: '/superuser/integration',
                    name: 'integration',
                    element: <Integration />
                  },
                  {
                    path: '/superuser/deliveries',
                    name: 'deliveries',
                    element: <Deliveries />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/user/login" />,
        hideInMenu: true
      }
    ]
  }
]

export default routes as Route[]
