import { DownloadOutlined, DownOutlined, DownSquareOutlined, SearchOutlined, UpSquareOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Space, Table, Tooltip, Typography } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { MenuItemType } from 'antd/es/menu/interface'
import { filtersSelector } from '../../redux/context/filters/selectors'
import { useReportTableData } from './hooks'
import { ReportTableProps, ReportTableRow } from './types'
import { getCellDataAttributes, hasMonthlyData, renderPeriod } from './utils'
import ErrorComponent from '../Error/ErrorComponent'
import { Column, useTableColumns } from '../../redux/context/periodGroups/hooks'
import {
  setPeriodGroupFilter,
  toggleCumulativeFilter,
  ToggleShowIncomeStatementPercentageChange
} from '../../redux/context/filters/actions'

import { financialStatementsPageSelector } from '../../redux/pages/budgeting/financialStatements/selectors'
import { showVoucherMenu } from '../Voucher/utils'
import { useVourcherModal } from '../Voucher/useVoucherModal'
import { downloadMenuItems, tableDownload } from '../../utils/tableExport'
import { getRowKey } from '../../pages/budgeting/financialStatements/utils'
import ToolBar from '../Misc/ToolBar'
import { formatValueByType } from '../../utils/helpers'
import { AppDispatch } from '../../redux/store'
import Ellipsis from '../Misc/Ellipsis'

const StyledTable = styled(Table)`
  .budgetable-row {
    background-color: #f9edff;
    box-shadow: inset 0 4px 9px -9px rgba(0, 0, 0, 0.3);
  }
  .dimension-row {
    background-color: #fff2e5;
  }
  .budgetable-row .ant-btn-default {
    margin-right: 8px;
  }

  .budget-column {
    background-color: rgba(181, 245, 236, 0.3);
  }

  .forecast-column {
    background-color: rgba(156, 197, 255, 0.3);
  }

  .forecast-column-bright {
    background-color: rgba(210, 228, 255, 0.3);
  }

  .comparativePeriod-column {
    background-color: rgba(180, 217, 236, 0.3);
  }

  .actuals-cumulative-column {
    background-color: rgba(243, 243, 243, 0.8);
  }

  .forecast-cumulative-column {
    background-color: rgba(246, 255, 237, 0.8);
  }

  .budget-cumulative-column {
    background-color: rgba(191, 247, 255, 0.8);
  }

  .comparativePeriod-cumulative-column {
    background-color: rgba(184, 232, 239, 0.8);
  }

  .diff-column {
    background-color: rgba(180, 219, 255, 0.8);
  }

  .comparative-diff-column {
    background-color: rgba(173, 229, 236, 0.8);
  }

  .children-row {
    background-color: #fffff4;
  }

  .children-row.first {
    box-shadow: inset 0 7px 9px -9px rgba(0, 0, 0, 0.3);
  }

  .children-row.last {
    box-shadow: inset 0 -4px 7px -7px rgba(0, 0, 0, 0.3);
  }

  .drop-over-downward td {
    border-bottom: 2px dashed #1890ff !important;
  }

  .drop-over-upward td {
    border-top: 2px dashed #1890ff !important;
  }

  .pointer {
    cursor: pointer;
    color: #1890ff !important;
  }

  .pointer-deactive {
    cursor: pointer;
    color: #8d9195 !important;
  }

  .bold {
    font-weight: bold;
  }
`
const defaultProps = {
  csvExport: true,
  columnWidth: 100,
  nameColumnWidth: 400,
  fiscalYearClosingLabel: false,
  report: []
}

const ReportTable: React.FC<ReportTableProps> = ({
  report,
  yScroll,
  footer,
  columnWidth,
  nameColumnWidth,
  error,
  enableCumulatives,
  toolbar: { csvExport, enablePercents, hidden: hiddenToolBar, leftContent } = {},
  rowKey,
  expandable,
  summary,
  renderCell,
  rowClassName,
  renderTitle,
  contextMenuItems,
  toggleAllRows
}: ReportTableProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const tableElement = useRef<HTMLDivElement>(null)
  const { cumulativeFilter, showIncomeStatemetPercentageChange } = useSelector(filtersSelector)
  const columns = useTableColumns(enableCumulatives)
  const { budgetsInEdit } = useSelector(financialStatementsPageSelector)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const dataSource = useReportTableData(report, columns)
  const [headerLoading, setHeaderLoading] = useState(false)
  const [tableColumns, setTableColums] = useState<ColumnProps<ReportTableRow>[]>([])
  const [voucherModal, setVoucherModalVisible] = useVourcherModal()

  const load = async (column: Column) => {
    setHeaderLoading(true)
    setTimeout(() => {
      dispatch(setPeriodGroupFilter(column.periodGroup))
      setHeaderLoading(false)
    }, 1000)
  }

  const renderCustomTitle = (column: Column) => {
    return column.expandable || hasMonthlyData(report, column) ? (
      <div>
        {column.month && enableCumulatives && (
          <div>
            <Button size="small" onClick={() => dispatch(toggleCumulativeFilter(column))}>
              {cumulativeFilter?.year === column.year && cumulativeFilter?.month === column.month
                ? t('financialStatementsPage:hide')
                : t('financialStatementsPage:cumulative')}
            </Button>
          </div>
        )}

        <Button
          className={Object.values(budgetsInEdit).some(val => val) ? 'pointer-deactive' : 'pointer'}
          disabled={Object.values(budgetsInEdit).some(val => val)}
          type="link"
          loading={headerLoading}
          style={{
            height: column.month && enableCumulatives ? 42 : undefined,
            paddingRight: '0px',
            whiteSpace: 'pre-wrap'
          }}
          onClick={() => load(column)}
        >
          {column.label}
        </Button>
      </div>
    ) : (
      column.label
    )
  }

  const toggleExpandAllRows = () => {
    const allRowKeys = dataSource.map(d => {
      if (rowKey && typeof rowKey === 'string') return d[rowKey]
      return `${d.key || d.id || d.code}-${d.title || d.name}`
    })
    toggleAllRows?.(allRowKeys)
    if (selectedRows.length > 0) {
      setSelectedRows([])
    } else {
      setSelectedRows(allRowKeys)
    }
  }

  const expandRows = (expanded: boolean, record: ReportTableRow) => {
    const id = record.key || getRowKey(record)
    const index = selectedRows.indexOf(id)
    index < 0 ? setSelectedRows([...selectedRows, id]) : setSelectedRows(selectedRows.filter((k, i) => index !== i))
  }

  const reportTableContextMenuItems = (row: ReportTableRow, column: Column): MenuItemType[] => {
    const items: {
      display: (row: ReportTableRow, column: Column) => boolean
      item: MenuItemType
    }[] = [
      {
        display: showVoucherMenu,
        item: {
          label: t('global:show-vouchers'),
          key: 'voucher',
          icon: <SearchOutlined />,
          onClick: () => setVoucherModalVisible(true, { row, column })
        }
      }
    ]

    return items.filter(item => item.display(row, column)).map(({ item }) => item)
  }

  const getContextMenuItems = (row: ReportTableRow, column: Column) => {
    const reportTableItems = reportTableContextMenuItems(row, column)
    const additionalItems = contextMenuItems ? contextMenuItems(row, column) : []
    return [...reportTableItems, ...additionalItems]
  }

  useEffect(() => {
    setTableColums([
      {
        title: (
          <>
            {toggleAllRows && (
              <Button
                type="text"
                icon={selectedRows.length > 0 ? <UpSquareOutlined /> : <DownSquareOutlined />}
                onClick={() => toggleExpandAllRows()}
              />
            )}
            {t(`reportTypes:${(dataSource as any[])?.[0]?.report}`, 'Tunnusluku')}
          </>
        ),
        key: 'title',
        fixed: 'left',
        ellipsis: true,
        width: nameColumnWidth,
        onCell: reportTableRow => {
          return {
            className: rowClassName?.(reportTableRow)
          }
        },
        render: (reportTableRow: ReportTableRow) => {
          let { title } = reportTableRow
          if (reportTableRow.customTitle) title = reportTableRow.customTitle
          if (renderTitle) {
            if (reportTableRow.period && renderPeriod(reportTableRow, dataSource)) {
              title = `${renderTitle(reportTableRow, { expandRows })} (${t(`formulaPeriod:${reportTableRow.period}`)})`
            }
            title = renderTitle(reportTableRow, { expandRows })
          }

          if (reportTableRow.period && renderPeriod(reportTableRow, dataSource)) {
            title = `${reportTableRow.title} (${t(`formulaPeriod:${reportTableRow.period}`)})`
          }

          return <Ellipsis style={{ width: nameColumnWidth }}>{title}</Ellipsis>
        }
      },
      // tässä tämä account on vain file exporttia varten ei näytetä frontissa
      // hax 0.1 leveys yms tyylit
      {
        title: t('global:account'),
        key: 'title',
        dataIndex: 'accountCode',
        className: 'hidden-column',
        width: 0.1,
        render: (val: number) => {
          return {
            children: null,
            props: {
              'data-v': val,
              'data-n': 'n'
            }
          } as any
        }
      },

      ...columns.map(
        column =>
          ({
            title: renderCustomTitle(column),
            align: 'right' as const,
            key: `${column.dataIndex}-${column.dataType}`,
            className: column.className,
            dataIndex: column.dataIndex,
            onCell: reportTableRow => {
              return (
                reportTableRow.className && {
                  className: `${reportTableRow.className} ${column.dataType}`
                }
              )
            },
            render: (value: number, record: ReportTableRow) => {
              const formatOptions = record.style?.decimals
                ? {
                    minimumFractionDigits: record.style?.decimals,
                    maximumFractionDigits: record.style?.decimals
                  }
                : undefined
              const formattedValue = value ? formatValueByType(value, record.variableType, formatOptions) : null
              const cell = renderCell ? renderCell(value, record, column) : formattedValue
              return {
                children: getContextMenuItems(record, column).length ? (
                  <Dropdown menu={{ items: getContextMenuItems(record, column) }} trigger={['contextMenu']}>
                    <div className="hover-link">{cell}</div>
                  </Dropdown>
                ) : (
                  cell
                ),

                props: getCellDataAttributes(record, undefined, value)
              }
            }
          } as ColumnProps<ReportTableRow>)
      )
    ])
  }, [
    columns,
    renderCell,
    renderTitle,
    showIncomeStatemetPercentageChange,
    selectedRows,
    dataSource,
    contextMenuItems,
    headerLoading
  ])

  const tableProps: any = {
    scroll: {
      x:
        (columns.length - 1) * (columnWidth || defaultProps.columnWidth) +
        (nameColumnWidth || defaultProps.nameColumnWidth),
      ...(yScroll && { y: yScroll })
    },
    ...(footer && { footer: () => footer })
  }

  const handleDownloadMenu = (key: string) => {
    tableDownload(tableElement, t(`reportTypes:${(dataSource as any[])?.[0]?.report}`, 'report'), key)
  }

  const percentButton = (
    <Tooltip placement="topRight" title={t('financialStatementsPage:show-percentage')}>
      <Button onClick={() => dispatch(ToggleShowIncomeStatementPercentageChange())}>
        {showIncomeStatemetPercentageChange ? t('global:currency-symbol') : '%'}
      </Button>
    </Tooltip>
  )

  const exportButton = (
    <Dropdown
      menu={{
        items: downloadMenuItems,
        onClick: ({ key }) => handleDownloadMenu(key)
      }}
      trigger={['click']}
    >
      <Button>
        <Space>
          <DownloadOutlined />
          {t('global:download')}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  )

  const reportTableToolBar = () => {
    const rightContent: JSX.Element[] = []
    if (enablePercents) {
      rightContent.push(percentButton)
    }
    if (csvExport) {
      rightContent.push(exportButton)
    }
    if (rightContent.length === 0) return null
    return (
      <ToolBar justifyContent="space-between">
        <Col>{leftContent}</Col>
        <Col>
          <Space.Compact block>{rightContent}</Space.Compact>
        </Col>
      </ToolBar>
    )
  }

  return (
    <div className="report-table-container" ref={tableElement}>
      {voucherModal}

      {!hiddenToolBar && reportTableToolBar()}
      <StyledTable
        className="report-table"
        size="small"
        dataSource={!error ? dataSource : []}
        columns={tableColumns}
        pagination={false}
        bordered={false}
        // virtual TODO
        indentSize={8}
        rowClassName={rowClassName}
        locale={{
          emptyText: error && <ErrorComponent error={error} />
        }}
        onRow={(record: ReportTableRow) => {
          return {
            style: record?.style
          }
        }}
        rowKey={rowKey || ((reportTableRow: ReportTableRow) => getRowKey(reportTableRow))}
        expandable={
          expandable || {
            expandedRowKeys: selectedRows.map(String),
            onExpand: expandRows
          }
        }
        summary={() => summary}
        {...tableProps}
      />
    </div>
  )
}

ReportTable.defaultProps = defaultProps

export default ReportTable
