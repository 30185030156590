import { useSelector } from 'react-redux'
import { appLanguageSelector } from '../../../redux/app/selectors'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { dimensionQuerySelector, filtersSelector } from '../../../redux/context/filters/selectors'
import { store } from '../../../redux/store'
import { Store } from '../../../redux/types'
import { Language } from '../../../types/user/User'

export const useContextParameters = () => {
  const state = store.getState() as unknown as Store
  const companyId = useSelector(contextCompanyIdSelector)
  const dimensionQuery = dimensionQuerySelector(state)
  const lng = appLanguageSelector(state)
  const { budgetingScenario, periodGroups } = filtersSelector(state)

  const contextParams = {
    companyId: companyId || null,
    periodGroups: periodGroups || [],
    lng: lng || Language.fi,
    dimensions: dimensionQuery || [],
    budgetingScenarioId: (budgetingScenario && budgetingScenario.id) || null
  }
  return contextParams
}
