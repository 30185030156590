import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { LoanInstalment } from '../types/LoanInstalment'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import { VariableType } from '../../../components/Table/types'
import EditableCellTable, { EditableColumnProps } from '../../../components/Table/EditableCellTable'
import Loading from '../../../pages/loading/Loading'
import { formatValueByType } from '../../../utils/helpers'
import { Loan } from '../types/Loan'
import { useLoanInstalments } from '../queries/useLoanInstalments'
import { useCreateLoanInstalmentsCorrection } from '../queries/useCreateLoanInstalmentsCorrection'
import { LoanInstalmentCorrection } from '../types/LoanInstallmentCorrection'
import { useUpdateLoanInstalmentsCorrection } from '../queries/useUpdateLoanInstalmentsCorrection'
import { useDeleteLoanInstalmentsCorrection } from '../queries/useDeleteLoanInstalmentsCorrection'

interface RepaymentScheduleProps {
  loanData: Loan
}

const RepaymentSchedule: React.FC<RepaymentScheduleProps> = ({ loanData }: RepaymentScheduleProps) => {
  const { t, i18n } = useTranslation()
  const queryClient = useQueryClient()
  const { data: loanInstalments, isLoading, isFetching } = useLoanInstalments(loanData.id)
  const createLoanInstalmentsCorrection = useCreateLoanInstalmentsCorrection(loanData.id)
  const updateLoanInstalmentsCorrection = useUpdateLoanInstalmentsCorrection(loanData.id)
  const deleteLoanInstalmentsCorrection = useDeleteLoanInstalmentsCorrection(loanData.id)

  const render = (text: string) => {
    return Number(text || 0).toLocaleString(i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  const columns: EditableColumnProps<LoanInstalment>[] = [
    {
      title: t('loans:instalment-date'),
      dataIndex: 'instalmentDate',
      key: 'instalmentDate',
      render: (text: string) => {
        return dayjs(text).format('L')
      }
    },
    {
      title: t('loans:period'),
      dataIndex: 'period',
      key: 'period',
      align: 'center'
    },
    {
      title: t('loans:opening-balance'),
      dataIndex: 'openingBalance',
      key: 'openingBalance',
      align: 'right',
      render
    },
    {
      title: t('loans:interest-value'),
      dataIndex: 'interestValue',
      key: 'interestValue',
      align: 'right',
      render
    },
    {
      title: t('loans:instalment-value'),
      dataIndex: 'instalmentValue',
      key: 'instalmentValue',
      align: 'right',
      render
    },
    {
      title: t('loans:management-fee'),
      dataIndex: 'managementFee',
      key: 'managementFee',
      align: 'right',
      render
    },
    {
      title: t('investmentsPage:correction'),
      dataIndex: ['correction', 'value'],
      align: 'right',
      width: '25%',
      render: (value: number) =>
        formatValueByType(value, VariableType.absolute, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }),
      editable: true,
      inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
        <FormattedInputNumber style={{ width: '100%' }} size="small" ref={ref} {...props} />
      ))
    },
    {
      title: t('loans:total-value'),
      dataIndex: 'totalValue',
      key: 'totalValue',
      align: 'right',
      render
    },
    {
      title: t('loans:closing-balance'),
      dataIndex: 'closingBalance',
      key: 'closingBalance',
      align: 'right',
      render
    }
  ]

  const onUpdate = (formFields: LoanInstalment, previousRecord: LoanInstalment) => {
    const value = formFields.correction?.value
    const { id, loanId } = previousRecord?.correction || {}

    const data: Partial<LoanInstalmentCorrection> = {
      id,
      loanId,
      year: formFields.year,
      month: formFields.month,
      value
    }

    // If value null or undefined DO NOTHING
    if (value == null) return

    const onSuccess = () =>
      queryClient.invalidateQueries({
        queryKey: [{ scope: 'loan', entity: 'instalments' }]
      })

    if (id) {
      if (value === 0) {
        deleteLoanInstalmentsCorrection.mutate(id, {
          onSuccess
        })
      } else {
        updateLoanInstalmentsCorrection.mutate(
          { data, correctionId: id },
          {
            onSuccess
          }
        )
      }
    } else {
      createLoanInstalmentsCorrection.mutate(data, {
        onSuccess
      })
    }
  }

  if (isLoading) return <Loading />

  return (
    <EditableCellTable<LoanInstalment>
      size="small"
      rowKey={(record: any) => `${record.year.toString()}-${record.month.toString()}`}
      dataSource={loanInstalments}
      columns={columns}
      onUpdate={onUpdate}
      pagination={false}
      loading={isFetching}
    />
  )
}

export default RepaymentSchedule
