import * as Highcharts from 'highcharts'
import HighchartsCustomEvents from 'highcharts-custom-events'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CustomEventsOptions } from '../../../../../config/highcharts/types'
import { Report } from '../../../../../redux/context/reports/types'
import { RequestError } from '../../../../../redux/error/types'
import ErrorComponent from '../../../../../components/Error/ErrorComponent'
import { useChartCategories, useChartSeries } from '../../../../../components/Chart/hooks'
import { useTableColumns } from '../../../../../redux/context/periodGroups/hooks'
import { setFiscalYearFilterEvent } from '../../../../../components/Chart/utils'
import './_KeyFigureChart.sass'
import { getPlotOptions } from '../../../../../components/Chart/plotOptions'
import Loading from '../../../../loading/Loading'
import { ChartType, ChartSpecialType } from '../../../../../components/Chart/types'
import ChartTypeSelect from './ChartTypeSelect'

HighchartsExporting(Highcharts)
HighchartsCustomEvents(Highcharts as any)

interface KeyFigureChartProps {
  report: Report
  chartType: ChartType
  height?: number
  width?: number
  error?: RequestError
  cumulative?: boolean
}

const KeyFigureChart: React.FC<KeyFigureChartProps> = ({
  report,
  chartType,
  height,
  width,
  error,
  cumulative
}: KeyFigureChartProps) => {
  const [graphType, setGraphType] = useState<ChartType>(chartType)
  const [specialColumnType, setSpecialColumnType] = useState<ChartSpecialType | undefined>(undefined)
  const columns = useTableColumns()
  const categories = useChartCategories()

  let actualType = !height ? graphType : chartType
  let actualSpecialType = specialColumnType

  if (actualType === ChartType.stackedColumn || actualType === ChartType.groupedColumn) {
    actualSpecialType = actualType
    actualType = ChartType.column
  }

  const series: any[] = useChartSeries(report, { cumulative, chartType: actualType })

  const options: CustomEventsOptions = {
    chart: {
      height,
      width,
      animation: false
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b><br/>',
      valueSuffix: ' {point.suffix}',
      shared: false
    },
    plotOptions: getPlotOptions(actualType, actualSpecialType),
    xAxis: {
      categories,
      labels: {
        ...setFiscalYearFilterEvent(columns, report, useDispatch())
      }
    },
    yAxis: {
      title: {
        text: null
      }
    },
    series
  }

  const selectGraphType = (e: ChartType) => {
    if (e === ChartType.stackedColumn || e === ChartType.groupedColumn) {
      setGraphType(ChartType.column)
      setSpecialColumnType(e)
    } else {
      setGraphType(e)
      setSpecialColumnType(undefined)
    }
  }

  return (
    <div className="chart-container">
      {error ? (
        <div style={{ padding: 16 }}>
          <ErrorComponent error={error} />
        </div>
      ) : (
        <div>
          {!height && <ChartTypeSelect graphType={graphType} selectGraphType={selectGraphType} />}

          {categories.find(c => c.includes('undefined')) ? (
            <Loading />
          ) : (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </div>
      )}
    </div>
  )
}

export default KeyFigureChart
