import i18n from 'i18next'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { User, UserPasswordCredentials, UserSetting } from '../../../types/user/User'
import { setContextCompany } from '../../context/company/actions'
import { GET, GetRequestAction, POST, PostRequestAction, PUT, PutRequestAction, REQUEST } from '../../middleware/types'
import { Store } from '../../types'
import { getSessionContractRequest } from '../contract/actions'
import {
  SetCurrentUserAction,
  SET_CURRENT_USER,
  AddCurrentUserCompanyAction,
  ADD_CURRENT_USER_COMPANY,
  UpdateTermsAndConditionsAction,
  UPDATE_TERMS_AND_CONDITIONS,
  UpdateTourAction,
  UPDATE_TOUR,
  UPDATE_USER_SETTINGS,
  UpdateUserSettingsAction,
  UpdateUserCompanyAction,
  UPDATE_CURRENT_USER_COMPANY
} from './types'
import { Company } from '../../../types/company/Company'
import { setDimensionsFilter } from '../../context/filters/actions'
import { logoutAction } from '../authentication/actions'

export const setCurrentUserAction = (user: User): SetCurrentUserAction => ({
  type: SET_CURRENT_USER,
  payload: user
})

export const updateTermsAndConditionsAction = (): UpdateTermsAndConditionsAction => ({
  type: UPDATE_TERMS_AND_CONDITIONS
})

export const updateTourAction = (): UpdateTourAction => ({
  type: UPDATE_TOUR
})

export const updateUserSettingsAction = (setting: UserSetting): UpdateUserSettingsAction => ({
  type: UPDATE_USER_SETTINGS,
  payload: setting
})

export const updateUserCompanyAction = (company: Company): UpdateUserCompanyAction => ({
  type: UPDATE_CURRENT_USER_COMPANY,
  payload: company
})

export const setCurrentUser = (user: User): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch: any) => {
    if (user) {
      const { companies } = user
      i18n.changeLanguage(user.language)
      dispatch(setCurrentUserAction(user))
      if (companies.length === 1) {
        const company = companies[0]
        const dimension = user.dimensions.find(d => d.companyId === company.id)
        dispatch(setContextCompany(company))
        if (!user.permissions.find(p => p.label === 'read-root-dimension') && dimension) {
          dispatch(setDimensionsFilter([dimension.dimensionId]))
        }
      }
    } else {
      dispatch(logoutAction())
    }
  }
}

export const addCurrentUserCompany = (company: Company): AddCurrentUserCompanyAction => ({
  type: ADD_CURRENT_USER_COMPANY,
  payload: company
})

export const getCurrentUserRequest = (): GetRequestAction => ({
  type: REQUEST,
  retry: true,
  payload: {
    method: GET,
    url: 'api/users/current',
    success: setCurrentUser
  },
  meta: {
    type: 'CURRENT_USER'
  }
})

export const updateCurrentUserRequest = (user: User): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: 'api/users/current',
    data: user,
    success: setCurrentUserAction
  },
  meta: {
    type: 'UPDATE_CURRENT_USER',
    notification: true
  }
})

export const updateCurrentUserPasswordRequest = (password: UserPasswordCredentials): PutRequestAction => ({
  type: 'REQUEST',
  payload: {
    method: PUT,
    url: `api/users/current/password`,
    data: password
  },
  meta: {
    type: 'UPDATE_PASSWORD',
    notification: true
  }
})

export const forgotPasswordRequest = (email: User['email']): PostRequestAction => ({
  type: 'REQUEST',
  payload: {
    method: POST,
    url: `api/forgot-password`,
    data: email
  },
  meta: {
    type: 'FORGOT_PASSWORD'
  }
})

export const resetPasswordRequest = (token: string, password: UserPasswordCredentials): PostRequestAction => ({
  type: 'REQUEST',
  payload: {
    method: POST,
    url: `api/reset-password/${token}`,
    data: password
  },
  meta: {
    type: 'RESET_PASSWORD',
    notification: true
  }
})

export const tourCompletedRequest = (): PutRequestAction => ({
  type: 'REQUEST',
  payload: {
    method: PUT,
    url: `api/users/current/tour-completed`,
    data: { tourCompleted: true },
    success: () => updateTourAction()
  },
  meta: {
    type: 'TOUR_COMPLETED'
  }
})

export const termsConditionsAgreedRequest = (): PutRequestAction => ({
  type: 'REQUEST',
  payload: {
    method: PUT,
    url: `/api/users/current/terms-and-conditions`,
    data: { termsAndConditionsAgreed: true },
    success: () => updateTermsAndConditionsAction()
  },
  meta: {
    type: 'TERMS_AND_CONDITIONS'
  }
})

export const currentUserSettingsRequest = (setting: UserSetting): PutRequestAction => ({
  type: 'REQUEST',
  payload: {
    method: PUT,
    url: `/api/users/current/settings/${setting.settingId}`,
    data: { value: setting.value },
    success: updateUserSettingsAction
  },
  meta: {
    type: 'UPDATE_CURRENT_USER',
    notification: true
  }
})
