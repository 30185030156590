/* eslint-disable @typescript-eslint/no-unused-vars */
import { PointOptionsObject } from 'highcharts'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHighchartTheme } from '../../config/highcharts/Theme'
import { filtersSelector } from '../../redux/context/filters/selectors'
import { useTableColumns } from '../../redux/context/periodGroups/hooks'
import { columnPeriodsSelector } from '../../redux/context/periodGroups/selectors'
import { Report, ReportDataType } from '../../redux/context/reports/types'
import { getRowData } from '../../utils/csvExport'
import { useReportTableData } from '../Table/hooks'
import { VariableType } from '../Table/types'
import { ChartBasicType } from './types'
import { getSerieOptions } from './utils'

interface ChartSeriesOptionTypes {
  dataType?: ReportDataType
  cumulative?: boolean
  chartType?: ChartBasicType
}

export const useChartSeries = (report: Report, options?: ChartSeriesOptionTypes) => {
  const { dataType: dt, cumulative, chartType } = { ...options }
  const { t } = useTranslation()
  const periods = useSelector(columnPeriodsSelector)
  const theme = useHighchartTheme()
  let columns = useTableColumns()
  const dataSource = useReportTableData(report, columns)

  if (dt) {
    columns = columns.filter(c => c.dataType === dt)
  }
  const columnsByDataType = _.groupBy(columns, 'dataType')

  const periodSeries = Object.entries(columnsByDataType).flatMap(([dataType, dataTypeColumns]) => {
    return dataSource.map((row, index) => {
      const name =
        dataType === ReportDataType.actuals
          ? row.customTitle || row.title
          : `${row.customTitle || row.title} ( ${t(`global:${_.kebabCase(dataType)}`)} )`

      const data: PointOptionsObject[] = []
      const serieOptions = getSerieOptions(dataType as ReportDataType, chartType)

      const rowData = getRowData(row, dataTypeColumns, cumulative)

      const getValueSuffix = () => {
        if (row.variableType === VariableType.percentage) {
          return '%'
        }
        return ''
      }

      data.push(
        ...rowData.map((y, x) => ({
          y,
          x,
          suffix: getValueSuffix(),
          name: dataTypeColumns[x].label
        }))
      )

      return {
        name,
        data,
        rowId: row.id,
        stack: dataType,
        opacity: dataType === ReportDataType.actuals ? 1 : 0.8,
        color: theme.colors[+index % theme.colors.length],
        ...serieOptions
      }
    })
  })

  let groupSeries = []
  if (columns.find(col => col.dataType === ReportDataType.comparativePeriod)) {
    groupSeries = Object.entries(columnsByDataType).flatMap(([dataType, dataColumn]) => {
      return dataSource.map((row, index) => {
        const data: number[] = []

        const serieOptions = getSerieOptions(dataType as ReportDataType, chartType)
        const rowData = getRowData(row, dataColumn, cumulative)
        data.push(...rowData)

        const name =
          dataType === ReportDataType.actuals
            ? row.customTitle || row.title
            : `${row.customTitle || row.title} ( ${t(`global:${_.kebabCase(dataType)}`)} )`

        return {
          name,
          data,
          rowId: row.id,
          color: theme.colors[+index % theme.colors.length],
          ...serieOptions
        }
      })
    })
  } else {
    groupSeries = dataSource.map((row, index) => {
      const data: number[] = []
      const serieOptions = getSerieOptions(ReportDataType.actuals, chartType)
      const rowData = getRowData(row, columns, cumulative)
      data.push(...rowData)

      return {
        name: row.customTitle || row.title,
        data,
        rowId: row.id,
        color: theme.colors[+index % theme.colors.length],
        ...serieOptions
      }
    })
  }

  return periods.length ? periodSeries : groupSeries
}

export const useChartCategories = (dataType?: ReportDataType) => {
  const periods = useSelector(columnPeriodsSelector)
  const { dataTypes } = useSelector(filtersSelector)
  let columns = useTableColumns()
  if (dataType) {
    columns = columns.filter(c => c.dataType === dataType)
  }

  const categories: string[] = []
  if (periods.length) {
    const columnsByDataType = _.groupBy(columns, 'dataType')
    const chartColumns = columnsByDataType[Object.keys(columnsByDataType)[0]] || []
    const periodCategories = chartColumns.map(column => `${column.month} / ${column.year}`)
    categories.push(...periodCategories)
  } else if (!periods.length) {
    if (JSON.stringify(dataTypes) === JSON.stringify([ReportDataType.comparativePeriod])) {
      const groupCategories = columns.map(column => column.label)
      categories.push(...groupCategories)
    } else {
      const groupCategories = columns
        .filter(c => c.dataType !== ReportDataType.comparativePeriod)
        .map(column => column.label)
      categories.push(...groupCategories)
    }
  }

  return categories
}
